var Menu = function () {
  'use strict';

  var globals = {
    menu: "#mobile-menu",
    additional: "",
    trigger: 1000,
    resizeTimer: null,
    flag: true,
    titles: false,
    prepend: {
      content: jQuery('#user-nav').clone().html(),
      to: "#mobile-nav-menu .block-inner"
    },
    append: {
      content: jQuery('#siebar-search').clone().html(),
      to: "#mobile-nav-menu .block-inner"
    }
  },
      init = function () {
    // Create Mobile Menu Markup
    var body = jQuery('body');
    body.prepend('<div class="mobile-nav-menu-wrapper" id="mobile-nav-menu-wrapper"></div>');
    var menuWrapper = jQuery('#mobile-nav-menu-wrapper');
    menuWrapper.prepend('<div class="menu-overlay"></div>');
    menuWrapper.prepend('<div id="mobile-nav-menu" class="header-fix"><div class="block-inner"><div class="content"><ul class="menu"></div></div></div></div>');
    var menuItems = jQuery(globals.menu + " ul").clone().find('li');
    jQuery('#mobile-nav-menu .menu').append(menuItems); // Optional

    process.prepend();
    process.append();
    process.titles();
    process.additional(true);
    jQuery(document).ready(function () {
      process.height();
    }); //Click Operations

    jQuery("#nav-icon").on('click', function () {
      body.toggleClass('nav-expanded');

      if (body.hasClass('nav-expanded')) {
        process.state(true);
      } else {
        process.state(false);
      }

      return false;
    }); //Disengages Mobile Menu when Toggle Width is Exceeded

    jQuery(window).on('resize', function () {
      if (globals.trigger != 0) {
        if (globals.resizeTimer) {
          window.clearTimeout(globals.resizeTimer);
        } // Clear Timer


        globals.resizeTimer = window.setTimeout(function () {
          if ((jQuery('body').hasClass('nav-expanded') || jQuery('#mobile-nav-menu').hasClass('visible')) && window.innerWidth >= globals.trigger) {
            process.disable();
          }
        }, 50); // New Timer
      }

      if (jQuery('body').hasClass('nav-expanded')) {
        jQuery('#main').css('height', jQuery('#mobile-nav-menu')[0].clientHeight);
      }

      process.height();
    });
    window.addEventListener("orientationchange", function () {
      process.height();
    });
    jQuery('.menu-overlay').on('click', function () {
      process.state(false);
    });
  },
      process = {
    state: function state(enabled) {
      var navIcon = jQuery('#nav-icon');
      var navMenu = jQuery('#mobile-nav-menu');
      var navOverlay = jQuery('.menu-overlay');
      var page = jQuery('#main');
      var body = jQuery('body');

      if (enabled) {
        navIcon.addClass('close');
        navMenu.addClass('visible');
        navOverlay.addClass('visible'); //page.css('height', navMenu[0].clientHeight)
      } else {
        // Disabled
        navIcon.removeClass('close');
        body.removeClass('nav-expanded transition-fix');
        navMenu.removeClass('visible');
        navOverlay.removeClass('visible'); //page.css('height', 'auto')

        globals.flag = false;
      } //Transition Fix (Component of Menu Scrolling)


      if (globals.flag == true) {
        body.delay(300).queue(function () {
          body.removeClass('transition-out').addClass('transition-in').clearQueue();
          globals.flag = false;
        });
      } else {
        globals.flag = true;
        body.delay(300).queue(function () {
          jQuery(this).addClass('transition-out').removeClass('transition-in').clearQueue();
        });
      }
    },
    prepend: function prepend() {
      if (globals.prepend.content != null) {
        var prepend = jQuery("<div>", {
          class: 'menu-header clearfix'
        }).html(globals.prepend.content);
        jQuery(globals.prepend.to).prepend(prepend);
      }
    },
    append: function append() {
      if (globals.append.content != null) {
        var append = jQuery("<div>", {
          class: 'menu-footer clearfix'
        }).html(globals.append.content);
        jQuery(globals.append.to).append(append);
      }
    },
    titles: function titles() {
      //Creates Subtitles for Menu items using the "Title" attribute.
      if (globals.titles == true) {
        jQuery('#mobile-nav-menu a').each(function () {
          var current = jQuery(this);
          current.append('<span class="title">' + current.attr('title') + '</div>').wrapInner('<div class="inner-wrapper">').wrapInner('<div class="nav-wrapper">');
        });
      }
    },
    height: function height() {
      /*
            var menu = jQuery('#mobile-nav-menu');
            var overlay = jQuery('.menu-overlay');
      
            if(menu.find('.block-inner').innerHeight() >= window.innerHeight) {
      
              menu.css('height', menu.find('.block-inner').innerHeight());
              overlay.css('height', menu.height());
            }
            else {
              var height = window.innerHeight;
              menu.css('height', height);
              overlay.css('height', height);
            }
      */
    },
    disable: function disable() {
      process.state(false);
    },
    additional: function additional(reverse) {
      if (jQuery(globals.additional).length) {
        var navMenu = jQuery('#mobile-nav-menu').find('.menu');

        if (reverse) {
          jQuery(globals.additional).find('li').reverse().each(function () {
            jQuery(this).addClass('additional');
            navMenu.prepend(jQuery(this).clone());
          });
        } else {
          jQuery(globals.additional).find('li').each(function () {
            jQuery(this).addClass('additional');
            navMenu.prepend(jQuery(this).clone());
          });
        }
      }
    }
  };

  if (this instanceof Menu) {
    init();
  } else {
    return new Menu();
  } //Creates a new Menu should it not be defined as new.

}; // Reverse Plugin added to jQuery


jQuery.fn.reverse = [].reverse;