$().ready(function () {
  $('a.upvote').each(function (i) {
    $(this).on('click', function (e) {
      e.preventDefault();
      var thisUrl = this.href;
      var container = $(this).parent().find('.votes');
      $.post(thisUrl, {
        rating: $(this).html()
      }, function (json) {
        json = eval('(' + json + ')');
        var result = [json.score.score, ' '];
        $(container).html(result.join(''));
      });
    });
  });
  $('a.downvote').each(function (i) {
    $(this).on('click', function (e) {
      e.preventDefault();
      var thisUrl = this.href;
      var container = $(this).parent().find('.votes');
      $.post(thisUrl, {
        rating: $(this).html()
      }, function (json) {
        json = eval('(' + json + ')');
        var result = ['-1', ' '];
        $(container).html(result.join(''));
      });
    });
  });
});