var map;

function initialize() {
  if (typeof default_latitude == "undefined") default_latitude = 35.842286;
  if (typeof default_longitude == "undefined") default_longitude = -90.463867;
  if (typeof default_zoom == "undefined") default_zoom = 3;
  latlng = new google.maps.LatLng(default_latitude, default_longitude);
  my_options = {
    zoom: default_zoom,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    center: latlng
  };
  map = new google.maps.Map(document.getElementById("map_canvas"), my_options);

  if (typeof initial_marks != 'undefined') {
    $(initial_marks).each(function (mark) {
      add_mark(map, this, false);
    });
  }
}

function add_mark(map, dict, is_self) {
  var point = new google.maps.LatLng(dict[1], dict[2]);
  if (typeof is_self == 'undefined') is_self = false;
  var size = new google.maps.Size(32, 32);
  var anchor = new google.maps.Point(16, 32);
  var icon = new google.maps.MarkerImage({
    anchor: anchor,
    size: size
  });
  var marker = new google.maps.Marker({
    position: point,
    map: map
  });
  marker.infoWindowAnchor = new google.maps.Point(16, 0);
  google.maps.event.addListener(marker, 'click', function () {
    if (typeof infowindow != "undefined") infowindow.close();
    infowindow = new google.maps.InfoWindow({
      content: mark_infowindow_content(map, dict)
    });
    infowindow.open(map, marker);
  });
}

function mark_infowindow_content(map, dict) {
  name = dict[0];
  lat = dict[1];
  lon = dict[2].toString();
  url = dict[3].toString();
  var html = "<div>" + "<p><a href=\"" + url + "\" class=\"map\" id=" + lat + "x" + lon + ">" + name + "</a></p>" + "</div>";
  return html;
}