window.onload = startGui;
var converter;
var convertTextTimer, processingTime;
var lastText, lastOutput, lastRoomLeft;
var convertTextSetting, paneSetting;
var inputPane, previewPane, syntaxPane;
var maxDelay = 3000;

function startGui() {
  convertTextSetting = 'continuous';

  if (!document.getElementById("preview")) {
    return false;
  }

  previewPane = document.getElementById("preview");
  paneSetting = document.getElementById("paneSetting");
  inputPane = document.getElementById("id_text");
  syntaxPane = document.getElementById("syntaxPane");
  convertTextSetting.onchange = onConvertTextSettingChanged;
  paneSetting.onchange = onPaneSettingChanged;
  window.onkeyup = inputPane.onkeyup = onInput;
  var pollingFallback = window.setInterval(function () {
    if (inputPane.value != lastText) onInput();
  }, 1000);

  inputPane.onpaste = function () {
    if (pollingFallback != undefined) {
      window.clearInterval(pollingFallback);
      pollingFallback = undefined;
    }

    onInput();
  };

  if (inputPane.addEventListener) {
    inputPane.addEventListener("input", inputPane.onpaste, false);
  }

  onPaneSettingChanged();
  converter = new Showdown.converter();
  convertText();
  previewPane.scrollTop = 0;
}

function convertText() {
  var text = inputPane.value;

  if (text && text == lastText) {
    return;
  } else {
    lastText = text;
  }

  var startTime = new Date().getTime();
  text = converter.makeHtml(text);
  var endTime = new Date().getTime();
  processingTime = endTime - startTime;
  saveScrollPositions();

  if (paneSetting.value == "previewPane") {
    previewPane.innerHTML = text;
  }

  lastOutput = text;
  restoreScrollPositions();
}

;

function onConvertTextSettingChanged() {
  onInput();
}

function onPaneSettingChanged() {
  if (paneSetting.value == "previewPane") {
    previewPane.innerHTML = lastOutput;
  } else if (paneSetting.value == "syntaxPane") {
    previewPane.innerHTML = syntaxPane.innerHTML;
  }
}

function onInput() {
  if (convertTextTimer) {
    window.clearTimeout(convertTextTimer);
    convertTextTimer = undefined;
  }

  if (convertTextSetting.value != "manual") {
    var timeUntilConvertText = 0;

    if (convertTextSetting.value == "delayed") {
      timeUntilConvertText = processingTime;
    }

    if (timeUntilConvertText > maxDelay) timeUntilConvertText = maxDelay;
    convertTextTimer = window.setTimeout(convertText, timeUntilConvertText);
  }
}

var previewScrollPos;
var outputScrollPos;

function getScrollPos(element) {
  if (element.scrollHeight <= element.clientHeight) return 1.0;
  return element.scrollTop / (element.scrollHeight - element.clientHeight);
}

function setScrollPos(element, pos) {
  element.scrollTop = (element.scrollHeight - element.clientHeight) * pos;
}

function saveScrollPositions() {
  previewScrollPos = getScrollPos(previewPane);
}

function restoreScrollPositions() {
  previewPane.scrollTop = previewPane.scrollTop;
  setScrollPos(previewPane, previewScrollPos);
}