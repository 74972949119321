/**
 * @name openLinks
 * @description Function to open links in new window. Needs confirmation.
 */
function openLinks() {
  $('.post .content a').on('click', function () {
    window.open(this.href, '', '');
    return false;
  });
}

$(document).ready(function () {
  var form = $('#reply'); // Post reply form

  $('.postcontrols').find('#inline-reply').each(function (i) {
    $(this).on('click', function (e) {
      e.preventDefault();
      var editor = CKEDITOR.instances.id_post_text;

      if (editor) {
        editor.destroy(true);
      }

      var theParent = this.parentNode.parentNode.parentNode;
      $(theParent).append($(form));
      CKEDITOR.replace('id_post_text', {
        toolbar: [['Bold', 'Italic', '-', 'RemoveFormat', 'Link', 'Unlink', 'Blockquote', 'Image', 'Embed', 'Smiley', 'SpecialChar', 'NumberedList', 'BulletedList', '-', 'Maximize', 'facebookvideo', 'Scayt', 'simpleuploads']],
        disallowedContent: 'img{width,height}'
      });
      CKEDITOR.config.extraPlugins = 'facebookvideo,scayt,embed,smiley,uploadimage,simpleuploads';
      CKEDITOR.config.scayt_autoStartup = true;
      CKEDITOR.config.filebrowserImageUploadUrl = '/ckeditor/upload/';
      CKEDITOR.config.filebrowserImageBrowseUrl = '';
      CKEDITOR.config.filebrowserBrowseUrl = '';
      var newEditor = CKEDITOR.instances.id_post_text;
      var replyText = '<p><strong>In reply to ' + $(theParent).find('.author_name').html() + ':</strong></p><p></p>';
      newEditor.setData(replyText);
      newEditor.config.contentsCss = '/static/dist/css/fonts/font-styles-editor.css';
      newEditor.on('instanceReady', function () {
        newEditor.focus();
        var range = newEditor.createRange();
        range.moveToElementEditEnd(range.root);
        newEditor.getSelection().selectRanges([range]);
      });
    });
  }); // Post quote form

  $('.postcontrols').find('#inline-quote').each(function (i) {
    $(this).on('click', function (e) {
      e.preventDefault();
      var editor = CKEDITOR.instances.id_post_text;

      if (editor) {
        editor.destroy(true);
      }

      var theParent = this.parentNode.parentNode.parentNode;
      $(theParent).append($(form));
      CKEDITOR.replace('id_post_text', {
        toolbar: [['Bold', 'Italic', '-', 'RemoveFormat', 'Link', 'Unlink', 'Blockquote', 'Image', 'Embed', 'Smiley', 'SpecialChar', 'NumberedList', 'BulletedList', '-', 'Maximize', 'facebookvideo', 'Scayt', 'simpleuploads']],
        disallowedContent: 'img{width,height}'
      });
      CKEDITOR.config.extraPlugins = 'facebookvideo,scayt,embed,smiley,uploadimage,simpleuploads';
      CKEDITOR.config.scayt_autoStartup = true;
      CKEDITOR.config.filebrowserImageUploadUrl = '/ckeditor/upload/';
      CKEDITOR.config.filebrowserImageBrowseUrl = '';
      CKEDITOR.config.filebrowserBrowseUrl = '';
      var newEditor = CKEDITOR.instances.id_post_text;
      var replyText = '<blockquote><strong>' + $(theParent).find('.author_name').html() + 'said:</strong><br />' + $(theParent).find('.content').html() + '</blockquote><p></p>';
      newEditor.setData(replyText);
      newEditor.config.contentsCss = '/static/dist/css/fonts/font-styles-editor.css';
      newEditor.on('instanceReady', function () {
        newEditor.focus();
        var range = newEditor.createRange();
        range.moveToElementEditEnd(range.root);
        newEditor.getSelection().selectRanges([range]);
      });
    });
  }); // Bad posts

  $('ul.postlist li.bad-post h4').append('<em class="note bad-post-message">- This comment has been collapsed</em>');
  $('ul.postlist li.bad-post h4').on('click', function () {
    $(this).parent().removeClass('bad-post');
  }); // Add to watchlist

  $('a.watch').on('click', function (e) {
    this.href = this.href + 'js/';
    e.preventDefault();
    var container = $(this);
    $.post(this.href, {
      rating: $(this).html()
    }, function (html) {
      // format result
      var result = [html, ' &radic;']; // output result

      $(container).html(result.join(''));
    });
  });
  $('ul.postlist li a.avatar').each(function (i) {
    var appended = 'false';
    $(this).on('click', function (e) {
      e.preventDefault();
      $('div.profile-info').hide();
      var thisUrl = this.href + 'js/';
      var container = $(this).parent().find('div.profile-info');
      $(container).animate({
        opacity: 'show'
      }, 'fast');
      $.post(thisUrl, {
        rating: $(this).html()
      }, function (html) {
        var p = eval('(" + html + ")');
        var results = '<ul><li><strong>Posts:</strong> ' + p.posts + '</li>' + '<li><strong>Joined:</strong> ' + p.joined + '</li>';

        if (p.city || p.state) {
          results += '<li><strong>Location:</strong> ' + p.city + ', ' + p.state + '</li>';
        }

        if (p.rides) {
          results += '<li><strong>Rides:</strong><ul>' + p.rides + '</ul></li>';
        }

        results += '<li><a href="/contact/' + p.username + '/">Contact</a></li>';
        results += '<li><a href="/community/' + p.username + '/">View full profile</a></li>';
        results += '</ul>';

        if (appended === 'false') {
          $(container).append('<span class="closer">X</span>');
          $(container).append(results);
          appended = 'true';
          $(container).on('click', function () {
            $(container).hide();
          });
        }
      });
    });
  });
});