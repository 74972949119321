$(document).ready(function () {
  $('body').addClass('js');
  $('#nav .nav-items li.search').on('click', function () {
    if (document.body.classList.contains('search-bar-visible')) {
      window.scrollTo({
        top: 0
      });

      if (window.scrollY <= 60) {
        $('body').removeClass('search-bar-visible');
      }
    } else {
      $('body').addClass('search-bar-visible');
      window.scrollTo({
        top: 0
      });
    }

    return false;
  });
  createMobileMenu(); // select box js

  $('.container-_16 select').addClass('fancy-select'); // $('select.fancy-select').sb();

  if (typeof current_month != 'undefined') {
    // calendar
    $('.calendar-table').calendarWidget({
      month: current_month - 1,
      year: current_year
    });
  }

  $('.start-lightbox').on('click', function (e) {
    e.preventDefault();
    $(this).closest('.block').find('.lightbox').first().click();
  });
  $('.show_all').each(function () {
    if ($(this).siblings('a.row').length > 2) {
      $(this).show();
      $(this).siblings('a.row:gt(1)').hide();
    }
  });
  $('.show_all').toggle(function () {
    $(this).siblings('a.row').show();
    $(this).html('Hide');
  }, function () {
    $(this).siblings('a.row:gt(1)').hide();
    $(this).html('Show all');
  }); // Sign count if > 0

  if ($('#vote .votes').html() > 0) {
    $('#vote .votes').html('+' + $('#vote .votes').html());
  } // Ajax call for voting


  $('#voting a').on('click', function () {
    var context = $(this);
    $.post(context.attr('href'), function (data) {
      data = JSON.parse(data);
      var score = data.score.score;

      if (score > 0) {
        score = '+' + score;
      }

      $('#vote .votes').html(score);
    });
    return false;
  });
  $('.postvote a').on('click', function () {
    var context = $(this);
    $.post(context.attr('href'), function (data) {
      data = JSON.parse(data);
      var score = data.score.score;

      if (score > 0) {
        if (score == 1) {
          score = '+ ' + score + ' Vote';
        } else {
          score = '+ ' + score + ' Votes';
        }
      }

      context.parent().find('.votes').html(score);
    });
    return false;
  });
});
/**
 * @name createMobileMenu
 * @description description
 */

function createMobileMenu() {
  var body = $('body');
  var mainSelect = $('#main-menu-select');
  mainSelect.find('option').each(function () {
    if (body.hasClass($(this).attr('class'))) {
      $(this).attr('selected', 'selected');
    }
  });
  $('#nav .inner').addClass('has-dropdown');

  if (mainSelect.find('option:selected').length == 0) {
    mainSelect.find('option').first().attr('selected', 'selected');
  }

  $('#main-menu-select').on('change', menuRedirect);
}
/**
 * @name menuRedirect
 * @description description
 */


function menuRedirect() {
  window.location = $(this).val();
}