/**
 * @name addFavorite
 * @description Function to add tags to your dashboard
 * @param {type} fav The favorite to add
 */
function addFavorite(fav) {
  $.ajax({
    type: 'POST',
    url: '/members/dashboard/api/add-make/',
    data: {
      'cars': [fav.find('input').val()]
    }
  }).done(function (msg) {
    fav.clearQueue();
    fav.delay(2000).queue(function () {
      fav.find('a').removeClass('savable').addClass('deletable').clearQueue();
    });
    jQuery('#ajax-message').html('<p>Reload your dashboard to see the changes take effect.</p>');
  });
}
/**
 * @name removeFavorite
 * @description Function to remove tags from your dashboard
 * @param {type} fav The tag to remove
 */


function removeFavorite(fav) {
  $.ajax({
    type: 'POST',
    url: '/members/dashboard/api/remove-make/',
    data: {
      'cars': [fav.find('input').val()]
    }
  }).done(function (msg) {
    fav.clearQueue();
    fav.delay(2000).queue(function () {
      fav.find('a').removeClass('deletable').removeClass('deleting').addClass('editable').find('.text').html('Add Favorite').clearQueue();
    });
    fav.find('input, select').val('');
    jQuery('#ajax-message').html('<p>Reload your dashboard to see the changes take effect.</p>');
  });
}

$(function () {
  $('.dashboard-favorites .favorite a').on('click', function () {
    var favorite = $(this).closest('.favorite');

    if ($(this).hasClass('editable')) {
      favorite.find('.drop-wrap').show();
      favorite.find('.text').html('');
    }

    if ($(this).hasClass('deletable')) {
      favorite.find('a').addClass('deleting');
      removeFavorite(favorite);
    }

    return false;
  });
  $('.dashboard-favorites .favorite select').on('change', function () {
    var favorite = $(this).closest('.favorite');
    var val = favorite.find('.drop-wrap').hide().find('select').val();
    favorite.find('.text').html(val);
    favorite.find('input').val(val);
    favorite.find('a').removeClass('editable').addClass('savable');
    addFavorite(favorite);
    return false;
  });
});